<template>
  <div class="mainTem">
    <!-- <div class="buyerHeader">
      <h3 class="buyerHeaderTit"><span>{{$t('i18nn_904c04c9f6cc2ce8')}}</span>
				<el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
				  <el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
				</el-tooltip>
			</h3>
    </div> -->
		
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{$t('i18nn_904c04c9f6cc2ce8')}}</span>
						<el-tooltip effect="dark" :content="$t('i18nn_ff6fd823ffab7e6b')" placement="top">
						  <el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
    <!-- <div style="text-align: center; margin-top: 10px;">
      <el-button-group>
        <el-button type="primary"  size="medium" style="width: 200px;">{{$t('i18nn_5b14580d813b4a45')}}</el-button>
        <el-button type="primary" plain size="medium" style="width: 200px;" @click="toPageUrl('PartnerBillSettlementDet')">{{$t('i18nn_1b613e926a1e65dd')}}</el-button>
      </el-button-group>
    </div> -->
    <!-- <div class="formMsgCon">
        <h3><i class="titleIcon el-icon-bell"></i>{{$t('i18nn_41413cddfa61538c')}}</h3>
        <div class="formMsgDet">
          <p>
            此为 我的费用明细
          </p>
        </div>
    </div> -->
   <!--  <div class="buyerDataHeader">
      <el-button type="primary" plain size="small" @click="openDioalog(null)">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
    </div> -->
		<div class="filterCon" style="">
			<div class="filterBarList">
		
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList"> -->
		
				<!-- </ul> -->
				<ul class="filterConList">
					<li>
						<span>{{$t('i18nn_f77f473d27f48bdc')}}</span>
						<SelAgentUser @change="changeAgentUser"></SelAgentUser>
					</li>
					<li>
						<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo"></whNoSelect>
					</li>
					<li>
						<span>{{ $t('i18nn_d42355e6aef72227') }}</span>
						<el-date-picker
							v-model="filterData.daterange"
							size="small"
							type="daterange"
							align="right"
							:clearable="false"
							unlink-panels
							range-separator="-"
							start-:placeholder="$t('i18nn_7d2ffc27a09ff792')"
							end-:placeholder="$t('i18nn_46f7be1133b3e2c6')"
							:picker-options="pickerOptions"
							format="yyyy-MM-dd"
							value-format="yyyy-MM-dd"
							@change="initData()"
						></el-date-picker>
					</li>
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input
							type="text"
							v-model="filterData.keyword"
							size="small"
							clearable
							@keyup.enter.native="initData()"
							maxlength="50"
							:placeholder="$t('i18nn_3ee4c9b76289e93a')"
							style="width: 180px;"
						/>
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
				</ul>
			</div>
		</div>
		
		
    <!-- <div class="wh-filter-con">
      <div class="wh-filter-item">
      	<span>{{$t('i18nn_f77f473d27f48bdc')}}</span>
      	<SelAgentUser @change="changeAgentUser"></SelAgentUser>
      </div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
				<whNoSelect @changeData="changWhNo"></whNoSelect>
			</div>
			
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_d42355e6aef72227') }}</span>
				<el-date-picker
					v-model="filterData.daterange"
					size="small"
					type="daterange"
					align="right"
					:clearable="false"
					unlink-panels
					range-separator="-"
					start-:placeholder="$t('i18nn_7d2ffc27a09ff792')"
					end-:placeholder="$t('i18nn_46f7be1133b3e2c6')"
					:picker-options="pickerOptions"
					format="yyyy-MM-dd"
					value-format="yyyy-MM-dd"
					@change="serPageData()"
				></el-date-picker>
			</div>
			
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
				<el-input
					type="text"
					v-model="filterData.keyword"
					size="small"
					clearable
					@keyup.enter.native="serPageData()"
					maxlength="50"
					:placeholder="$t('i18nn_3ee4c9b76289e93a')"
					style="width: 180px;"
				/>
			</div>
			
      <el-button type="primary" @click="serPageData" size="small">{{$t('FormMsg.Search')}}</el-button>

    </div> -->


    <div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
      
      <el-table
       :data="tableData"
       stripe
       :border="true"
       style="width: 100%"
       size="small"
       :max-height="$store.state.frameConHeightWh"
       >
        <el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>

        <el-table-column prop="cusNo" :label="$t('Storage.whFee.Customer_No')"></el-table-column>
				<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column> -->
        <!-- <el-table-column prop="putWhNo" :label="$t('i18nn_3eb79cfe71bbae0b')"></el-table-column> -->

        <!-- <el-table-column prop="putWhNo" :label="$t('Storage.skuInfo.product_information')" min-width="300">
          <template slot-scope="scope">
            <div>包装编号：{{ scope.row.packageNo }}</div>
            <div>产品代码：{{ scope.row.goodsSku }}</div>
            <div>{{$t('Storage.skuInfo.SKU_title')}}：{{ scope.row.goodsName }}</div>

            <div>
              <el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg" @click="openBigImg($event, scope.row.goodsImg)">
                <div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
              </el-image>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="feeTypeName" :label="$t('Storage.whFee.Fee_Type')">
					<template slot-scope="scope">
						{{ $Utils.i18nKeyText(scope.row,'feeTypeName') }}
					</template>
        </el-table-column>
        <el-table-column prop="feeAmt" :label="$t('Storage.whFee.Fee')">
        </el-table-column>
        <el-table-column prop="feeDescription" :label="$t('Storage.whFee.Fee_description')">

          <template slot-scope="scope">
            <div v-if="scope.row.feeDescriptionJson">
              <div>
                <span>{{$t('Storage.whFee.quantity')}}：</span>
                <span>{{scope.row.feeDescriptionJson.putWhCount}}</span>
              </div>
              <div>
              	<span>{{$t('i18nn_4d3765c415bb4462')}}</span>
              	:
              	<span>{{ scope.row.feeDescriptionJson.whAge }}</span>
              </div>
              <div>
              	<span>{{$t('i18nn_96e86c7f83dd7561')}}</span>
              	:
              	<span>{{ scope.row.feeDescriptionJson.price }}&nbsp;{{ scope.row.feeDescriptionJson.whFeeTypeName }}</span>
              </div>
							<div>
								<span><span>{{$t('i18nn_748bca3d68c277f5')}}</span>(<span>{{$t('i18nn_3258bbe488af3e79')}}</span>)</span>
								:
								<span>{{ scope.row.feeDescriptionJson.calcCycle }}</span>
							</div>
              <div v-if="scope.row.feeDescriptionJson.remark">
                <span>{{$t('Storage.tableColumn.remark')}}</span>
                <span>{{scope.row.feeDescriptionJson.remark}}</span>
              </div>
            </div>

          </template>
        </el-table-column>

        <el-table-column prop="feeDate" :label="$t('Storage.whFee.billing_date')">
        </el-table-column>
				<el-table-column prop="whNo" :label="$t('i18nn_5acbec83efb27445')">
				</el-table-column>
				<el-table-column prop="place" :label="$t('i18nn_3a6eb04ef8f3327b')">
				</el-table-column>
        <el-table-column prop="goodsSku" label="SKU">
          <template slot-scope="scope">
            <div v-if="scope.row.feeDescriptionJson">
              <div>
                <span>{{scope.row.feeDescriptionJson.goodsSku}}</span>
              </div>
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="goodsName" :label="$t('Storage.skuInfo.SKU_title')">
          <template slot-scope="scope">
            <div v-if="scope.row.feeDescriptionJson">
              <!-- <div>
                <span>{{scope.row.feeDescriptionJson.goodsName}}</span>
              </div>
              <div v-if="scope.row.feeDescriptionJson.goodsName!=scope.row.feeDescriptionJson.goodsNameEn">
                <span>{{scope.row.feeDescriptionJson.goodsNameEn}}</span>
              </div> -->
							<el-popover placement="top"
							  trigger="hover"
							  ><div class="pre-text">{{ scope.row.feeDescriptionJson.goodsName }}</div>
							  <span class="over_h120" slot="reference">{{scope.row.feeDescriptionJson.goodsName}}</span>
							</el-popover>
            </div>

          </template>
        </el-table-column>
        <!-- <el-table-column prop="goodsImg" :label="$t('Storage.skuInfo.SKU_pictures')">
          <template slot-scope="scope">
            <div v-if="scope.row.feeDescriptionJson">
              <div>
                <div>
                  <el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.feeDescriptionJson.goodsImg" @click="openBigImg($event, scope.row.feeDescriptionJson.goodsImg)">
                    <div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
                  </el-image>
                </div>
              </div>
            </div>

          </template>
        </el-table-column> -->
				<el-table-column prop="vol" :label="$t('i18nn_0e240c5802d17fea')">
					<template slot-scope="scope">
						<div v-if="scope.row.feeDescriptionJson">
							<div>
								<span>{{ scope.row.feeDescriptionJson.vol }}</span>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="inWhDate" :label="$t('i18nn_9eaa906ebc446062')">
					<template slot-scope="scope">
						<div v-if="scope.row.feeDescriptionJson">
							<div>
								<span>{{ scope.row.feeDescriptionJson.inWhDate }}</span>
							</div>
						</div>
					</template>
				</el-table-column>
        <!-- <el-table-column :label="$t('Storage.tableColumn.operation')" width="80" align="center"> -->
          <!-- <template slot-scope="scope"> -->
            <!-- <span v-if="1==scope.row.state">
              <el-button @click="sureBillAction(scope.row,$t('i18nn_4f5bb4ff8b3d804b'),'2')" type="primary" size="small">{{$t('i18nn_4f5bb4ff8b3d804b')}}</el-button>
            </span> -->
            <!-- <span v-else>
              <el-button  @click="noConfirm()" type="text" size="small">{{$t('i18nn_204ffab8a6e01870')}}</el-button>
            </span> -->
          <!-- </template> -->
        <!-- </el-table-column> -->
       <!--  <el-table-column :label="$t('Storage.tableColumn.operation')" width="80" align="center">
          <template slot-scope="scope">
            <span v-if="1==scope.row.state">
              <el-button @click="sureBillAction(scope.row,$t('i18nn_15f3bfa87d2f503e'),'3')" type="primary" size="small">{{$t('i18nn_15f3bfa87d2f503e')}}</el-button>
            </span>
            <span v-else-if="2==scope.row.state">
              <el-button @click="sureBillAction(scope.row,$t('i18nn_45d95c470c095145'),'3')" type="warning" size="small">{{$t('i18nn_45d95c470c095145')}}</el-button>
            </span>
          </template>
        </el-table-column> -->


      </el-table>
      <div class="tableCon_pagination">
        <hy-page-pagination :pagination='pagination' v-on:updatePageData="getPageData"></hy-page-pagination>
      </div>
    </div>
    <!-- 修改弹窗 -->
    <!-- <el-dialog :close-on-click-modal="false"  :title="$t('i18nn_62d1f13b854eb1f7')" append-to-body :visible.sync="dialogFormVisible">
      <el-form ref="form" :rules="formRules" :model="form" label-width="100px" v-loading="loading" :element-loading-text="$t('tips.Submitting')">

        <el-form-item label="用户账号：" prop="mobile">
          <el-input v-if="0==dialogFormStatus" v-model="form.mobile" :placeholder="$t('i18nn_29799f539ee6b906')" maxlength="11"></el-input>
          <el-input v-else v-model="form.mobile" maxlength="11" readonly></el-input>
        </el-form-item>

        <el-form-item label="用户姓名：" prop="subUserName">
          <el-input v-model="form.subUserName" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
        </el-form-item>

        <el-form-item label="所属厂家：">
          <el-input v-model="UserInfo.enterpriseName" readonly></el-input>
        </el-form-item>

        <el-form-item label="是否有效：" prop="state">
          <el-switch
            v-model="form.state"
            :active-text="$t('i18nn_02ccd2cf723f9272')"
            :inactive-text="$t('i18nn_e76d885ae1c74d4f')">
          </el-switch>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="dialogFormVisible = false">{{$t('FormMsg.Close')}}</el-button>
        <el-button type="primary" @click="submitForm('form')">{{$t('FormMsg.Save')}}</el-button>
      </div>
    </el-dialog> -->
		<whExcelCustom
			:openTime="excelOpenTime"
			:excelHead="excelHead"
			:excelData="expExcelData"
			:pagination="pagination"
			:excelName="excelName"
			:excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhMyFeePageList"
			:expHttpFilter="pageFilterData()"
		></whExcelCustom>
  </div>
</template>
<script>
import SelAgentUser from '@/components/Common/SelAgentUser.vue';
import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
export default {

  // name: 'BuyerOrder',
  //meta信息seo用
  // metaInfo: {
  //   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
  //   meta: [{ // set meta
  //     name: '互易天下-买家中心-收货账单',
  //     content: '互易天下-厂家共享平台-买家中心-收货账单'
  //   }],
  //   // link: [{                 // set link
  //   //   rel: 'asstes',
  //   //   href: 'https://assets-cdn.github.com/'
  //   // }]
  // },
  // props: ['isDialog'],
	components: {
	 SelAgentUser,
	 whExcelCustom,
	 whNoSelect
	},
  data() {
    return {
			//excel导出
			excelOpenTime: '',
			excelHead: [],
			expExcelData: [],
			excelName: '',
			excelOption: {},
      // activeName: 'second',
      // isShowFrom:false,
     pickerOptions: this.$PickerDate.pickerOptions_2(),
      // UserInfo: this.$store.getters.getUserInfo,
      // dialogFormVisible: false,
      // dialogFormStatus: 0, //0-新增，1-修改
      loading: false,
      // form: {
      //   "mobile":"",//子账户账号（手机号）
      //   "subUserId": "", //  子账户id
      //   "userSubUserId": "", //主账户子账户关系表id
      //   "subUserName": "", // 子账户名称
      //   "state": true, //子账户状态，0-有效，1-无效
      // },

      // formRules: {
      //   mobile: [
      //     { required: true, message: this.$t('i18nn_29799f539ee6b906'), trigger: 'blur' },
      //     { pattern: this.$Validate.REGEX_PHONE_International, message: this.$t('i18nn_f5a9e0830d476523')}
      //   ],
      //   subUserId: [
      //     { required: true, message: this.$t('i18nn_4b7dc78116a958d3'), trigger: 'blur' },
      //   ],
      //   userSubUserId: [
      //     { required: true, message: '请输入主账户子账户关系表ID', trigger: 'blur' },
      //   ],
      //   subUserName: [
      //     { required: true, message: this.$t('i18nn_a47da648bd79c2fc'), trigger: 'blur' },
      //   ],
      //   state: [
      //     { required: true, message: this.$t('i18nn_3821259f4f037ccf'), trigger: 'blur' },
      //   ],

      // },
			selectOption: {
				// wh_no: []
			},
      loading_load: false,
      tableData: [],
      //分页数据
      pagination: this.$Utils.defaultPagination(),
      //查询，排序方式
      filterData: {
        "orderBy": "id_", //排序字段
        "sortAsc": "desc", //desc降序，asc升序
				
				agentUser:"",
        // "accountPeriod":"",
        cusNo:"",
				whNo:'',
				daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this.$Utils.fomatterDate_YYYYMMdd(new Date())],
				keyword: '',
        // "billNo":""
      }
    }
  },

  //创建时
  created() {
    // this.getPageData();

  },
  //编译挂载前
  mounted() {
		// this.getDicData();
		
    this.initData();
  },
  methods: {
    initData() {
      this.pagination.current_page = 1;
      // this.currentSel = {};
      // this.multipleSelection = [];
      this.getPageData();
      // this.getDicData();
    },
		changeAgentUser(val){
			this.filterData.agentUser = val;
			this.initData();
		},
		changWhNo(data) {
			console.log('changWhNo', data);
			this.filterData.whNo = data.code;
			// this.filterData.userId = data.userId;
			this.initData();
		},
		//导出 excel
		exportExcel() {
		
			let columns = [
				// {
				// 	title: this.$t('i18nn_209fdd8f47e20fbf'),
				// 	key: 'cusNo'
				// },
				// {
				// 	title: this.$t('i18nn_29991afa9781d554'),
				// 	key: 'cusName'
				// },
				{
					title: this.$t('i18nn_46c3f6e0f657e7a3'),
					key: 'feeTypeName'
				},
				{
					title: this.$t('i18nn_d7315fb8114eb446'),
					key: 'feeAmt'
				},
				{
					title: this.$t('i18nn_3775920d44088b96'),
					key: 'putWhCount',
					types: 'custom',
					formatter: function(row) {
						// <div v-if="scope.row.feeDescriptionJson">
						//   <div>
						//     <span>{{$t('3775920d44088b96')}}</span>
						//     <span>{{scope.row.feeDescriptionJson.putWhCount}}</span>
						//   </div>
						//   <div>
						//     <span>{{$t('96e86c7f83dd7561')}}</span>
						//     <span>{{scope.row.feeDescriptionJson.whFeeTypeName}}</span>
						//   </div>
						//   <div v-if="scope.row.feeDescriptionJson.remark">
						//     <span>{{$t('15b3627faddccb1d')}}</span>
						//     <span>{{scope.row.feeDescriptionJson.remark}}</span>
						//   </div>
						// </div>
						return row.feeDescriptionJson.putWhCount;
					}
				},
				{
					title: this.$t('i18nn_4d3765c415bb4462'),
					key: 'whAge',
					types: 'custom',
					formatter: function(row) {
						return row.feeDescriptionJson.whAge;
					}
				},
				{
					title: this.$t('i18nn_96e86c7f83dd7561'),
					key: 'whFeeTypeName',
					types: 'custom',
					formatter: function(row) {
						return  row.feeDescriptionJson.price+' '+row.feeDescriptionJson.whFeeTypeName;
					}
				},
				{
					title: '计费周期(天)',
					key: 'calcCycle',
					types: 'custom',
					formatter: function(row) {
						return  row.feeDescriptionJson.calcCycle;
					}
				},
				{
					title: this.$t('i18nn_9bfcf061ccdd5c1c'),
					key: 'feeDate'
				},
				{
					title: this.$t('i18nn_5acbec83efb27445'),
					key: 'whNo'
				},
				{
					title: this.$t('i18nn_3a6eb04ef8f3327b'),
					key: 'place'
				},
				{
					title: 'SKU',
					key: 'goodsSku',
					types: 'custom',
					formatter: function(row) {
						return row.feeDescriptionJson.goodsSku;
					}
				},
				{
					title: 'SKU名称',
					key: 'goodsName',
					types: 'custom',
					formatter: function(row) {
						return row.feeDescriptionJson.goodsName + ' ' + row.feeDescriptionJson.goodsNameEn;
					}
				},
				{
					title: this.$t('i18nn_0e240c5802d17fea'),
					key: 'vol',
					types: 'custom',
					formatter: function(row) {
						return row.feeDescriptionJson.vol;
					}
				},
				{
					title: this.$t('i18nn_9eaa906ebc446062'),
					key: 'inWhDate',
					types: 'custom',
					formatter: function(row) {
						return row.feeDescriptionJson.inWhDate;
					}
				},
				{
					title: this.$t('i18nn_15b3627faddccb1d'),
					key: 'remark',
					types: 'custom',
					formatter: function(row) {
						return row.feeDescriptionJson.remark;
					}
				}
			];
			let Data = this.tableData;
			
			// let data_dom = JQ('#ex_table').find(".el-table__body");
			// let obj_key_img = {};
			// // console.log(data_dom.find("tr"));
			// data_dom.find("tr").each((index, domEle)=>{
			// 	// console.log("index",index);
			// 	let imgObj = JQ(domEle).find("td .cell img");
			// 	// console.log("imgObj",imgObj);
			// 	if(imgObj){
			// 		obj_key_img[imgObj.attr("code")] = imgObj.attr("src");
			// 	}
			// })
			// // console.log("obj_key_img",obj_key_img);
			// Data.forEach(item=>{
			// 	item.barcodeimg = obj_key_img[item.whNo + '-' + item.place];
			// })
			this.expExcelData = Data;
			this.excelHead = columns;
			this.excelName = 'agentWarehouseFee';
			this.excelOption = {height: 20};
			this.excelOpenTime = new Date().getTime();
			// excelUtilsNew.exportExcel(columns, Data, 'wh_InventoryStatistics',{height:20});
		},
		
    //跳转页面
    // toPageUrl(name){
    //   this.$router.push({'name':name});
    // },
    //打开新增编辑，弹窗
    // openDioalog(formParm) {
    //   // console.log(formParm);
    //   // this.dialogFormVisible = true;
    //   // let form = Object.assign({}, formParm);
    //   // console.log('form',form);
    //   // // 重置
    //   // this.resetForm('form');
    //   // if (null === formParm) { //新增

    //   //   this.dialogFormStatus = 0;
    //   //   form.subUserId = null;
    //   //   form.userSubUserId = null;
    //   //   form.state = true;
    //   //   //浅拷贝、对象属性的合并
    //   //   this.form = form;

    //   // } else { //修改
    //   //   this.dialogFormStatus = 1;
    //   //   form.state = form.state === '0' ? true : false;

    //   //   //浅拷贝、对象属性的合并
    //   //   this.form = form;

    //   // }
    //   this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
    //         type: 'warning',
    //         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
    //       });
    // },
    //点击状态为非出账弹出窗
    // noConfirm(){
    //   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
    //         type: 'warning',
    //         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
    //       });
    // },
    //提交信息
    // submitForm(formName) {

    //   this.$refs[formName].validate((valid) => {

    //     if (valid) {
    //       let formData = Object.assign({}, this.form);
    //       //浅拷贝、对象属性的合并
    //       if (0 === this.dialogFormStatus) {

    //         formData.id = null;

    //         this.postData(this.$urlConfig.subUserManaAdd, formData);
    //       } else {

    //         this.postData(this.$urlConfig.subUserManaUpdate, formData);
    //       }

    //     } else {
    //       console.log('error submit!!');
    //       this.$alert(this.$t("tips.checkSubmitData"), this.$t('tips.tipsTitle'), {
    //         type: 'warning',
    //         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
    //       });
    //       return false;
    //     }
    //   });
    // },
    //重置输入框
    // resetForm(formName) {
    //   console.log(formName);
    //   // console.log(this.$refs[formName]);

    //   if (this.$refs[formName]) {
    //     this.$refs[formName].resetFields();

    //   } else {
    //     console.log('this.$refs[formName]',this.$refs[formName]);
    //   }
    // },

    //提交信息
    // postData(url, formData) {
    //   let _this = this;
    //   this.loading = true;

    //   formData.state = formData.state ? '0' : '1';
    //   this.$http.post(url, formData)
    //     .then(({ data }) => {
    //       console.log(this.$t('tips.submitSuccess'));
    //       console.log(data);
    //       
    //       this.loading = false;
    //       if (200 == data.code) {
    //         this.dialogFormVisible = false;
    //         this.getPageData();
    //         this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
    //           type: 'success',
    //           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
    //         });
    //       } else {
    //         // if (!data.msg) {
    //         //   data.msg = this.$t('tips.submitError');
    //         // }
    //         this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
    //           type: 'warning',
    //           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       console.log(this.$t("tips.submitError"));
    //       _this.loading = false;
    //       this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
    //         type: 'warning',
    //         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
    //       });
    //     });
    // },
    //查询数据
      // serPageData(){
      //   this.pagination.current_page = 1;
      //   this.getPageData();
      // },
			
			//分页的筛选项数据
			pageFilterData() {
				// let _this = this;
				
				let startTime = '';
				let endTime = '';
				if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
					startTime = this.filterData.daterange[0];
					endTime = this.filterData.daterange[1];
				} else {
					this.$message.warning(this.$t('tips.Please_Sel_date'));
				}
				return {
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					// "partnerAccount": this.$store.getters.getUserInfo.partnerId,
					"cusNo": this.filterData.cusNo ? this.filterData.cusNo : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,
					feeDateStart: startTime ? startTime : null,
					feeDateEnd: endTime ? endTime : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// "accountPeriod":this.filterData.accountPeriod,//账期
					// "billNo":this.filterData.billNo,//账单编号
				};
			},
			
    //请求分页数据
    getPageData() {
      // let _this = this;
			
			let filterData = Object.assign(
				{
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size //当前页显示数目
				},
				this.pageFilterData()
			);
			
      this.loading_load = true;
      this.$http.put(this.$urlConfig.agentWhMyFeePageList, filterData)
        .then(({ data }) => {

        console.log(this.$store.getters.getUserInfo);
          console.log("账单分页，请求成功");
          console.log(data);
          
          this.loading_load = false;
          //表格显示数据
          this.tableData = data.rows;
          //当前数据总条数
          this.pagination.total = parseInt(data.total);
          //当前页数
          // this.pagination.current_page = parseInt(data.current);
          //当前页条数
          // this.pagination.page_size = parseInt(data.size);


        })
        .catch((error) => {
          console.log(error);
          console.log("账单分页，请求失败");
          this.loading_load = false;
        });
    },
		//查询数据字典
		// getDicData() {
		//   // let _this = this;
		//   // console.log(keyword);
		
		//   // this.loading_load = true;
		//   this.$http
		//     .put(this.$urlConfig.HyDicQueryList, ['wh_no'])
		//     .then(({ data }) => {
		//       console.log('查询数据字典，请求成功');
		//       console.log(data);
		//       if (200 == data.code && data.data) {
		//         // this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
		//         // this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
		//         // this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
						
		//   //       this.selectOption.wh_size_unit = data.data['wh_size_unit'];
		//   //       this.selectOption.wh_packing_type = data.data['wh_packing_type'];
		// 				// this.selectOption.wh_container_size = data.data['wh_container_size'];
		// 				this.selectOption.wh_no = data.data['wh_no'];
		//       } else {
		//         if (!data.msg) {
		//           data.msg = this.$t("tips.submitError");
		//         }
		//         this.$message.warning(data.msg);
		//       }
		//     })
		//     .catch(error => {
		//       console.log(error);
		//       console.log('查询数据字典接口，请求失败');
		//       this.$message.error(this.$t("tips.submitRequestError"));
		//     });
		// }
    //操作
    // sureBillAction(row,msg,state) {
    //   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
    //   console.log(row);
    //   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
    //     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
    //     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
    //     type: 'warning'
    //   }).then(() => {
    //     //确认
    //     let dataParm = {
    //       ids:[row.id],
    //       state:state,
    //     }
    //     this.updatePageData(dataParm, msg);
    //   }).catch(() => {

    //   });


    // },
    //更新特定数据,
    // updatePageData(dataParm, msg) {
    //   // let _this = this;
    //   this.loading_load = true;
    //   //浅拷贝、对象属性的合并
    //   let dataParmAss = Object.assign({}, dataParm);
    //   // dataParmAss.isDefault = 1;
    //   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
    //     .then(({ data }) => {
    //       console.log(msg + "特定数据，请求成功");
    //       console.log(data);
    //       if (200 == data.code) {
    //         this.$message.success(msg + '成功！');
    //         this.getPageData();
    //       } else {
    //         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       console.log("更新特定数据，请求失败");
    //       this.loading_load = false;
    //       this.$message.warning('' + msg + '失败,请重试！');
    //     });
    // }

  }
}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
